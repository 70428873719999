// @flow

import type { SiteAnalysisStep } from "./src/components/site-analysis-step-list";

import * as React from "react";
import { useReducer } from "react";
import { reducer } from "./reducer";

export type TCMApiInfo = {
  job_id: ?string,
};

export type TCMFormInfo = {
  adjectives_selected: Array<string>,
  adjectives_shown: Array<string>,
  website_url: ?string,
};

export type TCMContextState = {
  cm_api_info: TCMApiInfo,
  form_info: TCMFormInfo,
  siteAnalysisStep: SiteAnalysisStep,
};

export type TDispatchAction = { type: string, payload: TCMContextState };

type TCMContextDispatch = (action: TDispatchAction) => TCMContextState;

type TCMContext = {
  state: TCMContextState,
  dispatch: TCMContextDispatch,
};

export const initialState: TCMContextState = {
  cm_api_info: {
    job_id: null,
  },
  form_info: {
    adjectives_selected: [],
    adjectives_shown: [],
    website_url: null,
  },
  siteAnalysisStep: "scanning",
};

export const CMContext: React$Context<TCMContext> = React.createContext({
  state: initialState,
  dispatch: (action: TDispatchAction): TCMContextState => initialState,
});

const Provider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue: { state: TCMContextState, dispatch: Function } = {
    state: state,
    dispatch: dispatch,
  };

  return (
    <CMContext.Provider value={contextValue}>
      {props.children}
    </CMContext.Provider>
  );
};

export default ({ element }: { element: React.Node }): React.Node => (
  <Provider>{element}</Provider>
);
