// @flow

import type { TCMContextState, TDispatchAction } from "./provider";

import { initialState } from "./provider";

export type TAction = {
  SET_ADJS: string,
  SET_JOB_ID: string,
  SET_SITE_STEP: string,
};
export const ACTION: TAction = {
  SET_ADJS: "setAdjs",
  SET_JOB_ID: "setJobId",
  SET_SITE_STEP: "setSiteStep",
};

export const reducer = (
  state: TCMContextState,
  action: TDispatchAction,
): TCMContextState => {
  switch (action.type) {
    case ACTION.SET_ADJS:
      return {
        ...state,
        form_info: action.payload.form_info,
      };
    case ACTION.SET_JOB_ID:
      return {
        ...initialState,
        cm_api_info: action.payload.cm_api_info,
        form_info: action.payload.form_info,
      };
    case ACTION.SET_SITE_STEP:
      return {
        ...state,
        siteAnalysisStep: action.payload.siteAnalysisStep,
      };
    default:
      return initialState;
  }
};
